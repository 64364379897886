import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="footer-top">
                    <Col xs={12} md={6} className="footer-brand">
                        <h5>Xavier Flabat</h5>
                        <p className="footer-rights">© 2024 - Tous droits réservés</p>
                        <div className="footer-social">
                            <a href="https://www.youtube.com/@xavierflabat365" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/xavierflabat/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <FaInstagram />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="footer-credits">
                        <p>Créé par <a href="https://www.linkedin.com/in/pierre-romain-lopez/" target="_blank" rel="noopener noreferrer">Pierre-Romain LOPEZ</a></p>
                        <p>
                            Gérant de <a href="https://reactive-tech-solutions.com/" target="_blank" rel="noopener noreferrer">Reactive Tech Solutions</a>
                        </p>
                        <div className="footer-links">
                            <Link to="/privacy-policy">Politique de confidentialité</Link>
                            <span>|</span>
                            <Link to="/terms-and-conditions">Termes et conditions</Link>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-bottom">
                    <Col className="text-center">
                        <p>Suivez-moi sur mes réseaux sociaux pour rester informé des dernières actualités.</p>
                        <div className="footer-social-icons">
                            <a href="https://www.youtube.com/@xavierflabat365" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/xavierflabat/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <FaInstagram />
                            </a>
                            <a href="https://www.linkedin.com/in/pierre-romain-lopez/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                <FaLinkedin />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
