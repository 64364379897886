// Home.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import xavierImage from '../assets/xavier.jpg';
// Importez vos 3 images
import xavierBioImage from '../assets/XF.jpg';
import xavierBioImageXD from '../assets/XD.jpg';
import xavierBioImageXE from '../assets/XE.jpg';
import { useInView } from 'react-intersection-observer';
import YouTubeVideos from './YouTubeVideos';
import ArticlesDisplay from './ArticlesDisplay';
import FeaturedArtists from './FeaturedArtists';

function Home() {
  const [loaded, setLoaded] = useState(false);
  // State pour l'image actuellement affichée (index)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Tableau contenant toutes les images à afficher
  const images = [xavierBioImage, xavierBioImageXD, xavierBioImageXE];

  // Pour l'animation du jumbotron (chargement)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // Changement d'image toutes les 5 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5000 ms = 5 secondes
    return () => clearInterval(interval);
  }, [images.length]);

  const { ref, inView } = useInView({
    threshold: 0.2, // 20 % de l'élément visible pour déclencher l'animation
    triggerOnce: true,
  });

  const scrollToBioSection = () => {
    const bioSection = document.getElementById('bio-section');
    if (bioSection) {
      bioSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="home-container">
      <div
        className={`jumbotron home ${loaded ? 'loaded' : ''}`}
        style={{ backgroundImage: `url(${xavierImage})` }}
        role="img"
        aria-label="Xavier Flabat, ténor opéra"
      >
        {/* Image cachée pour l'accessibilité et le SEO */}
        <img
          src={xavierImage}
          alt="Xavier Flabat, ténor opéra"
          className="visually-hidden"
        />
        <div className="scroll-indicator" onClick={scrollToBioSection}>
          <span className="arrow"></span>
        </div>
      </div>


      <section
        id="bio-section"
        ref={ref}
        className={`bio-section ${inView ? 'slideIn' : ''}`}
      >
        {/* Affichage de l'image en fonction de l'index actuel */}
        <img
          src={images[currentImageIndex]}
          alt="Xavier Flabat, ténor opéra"
          title="Xavier Flabat, ténor opéra"
          className="bio-image"
          loading="lazy"
        />
        <div className="bio-text">
          <p>
            Xavier Flabat débute sa formation vocale au Conservatoire de Lille et se perfectionne auprès de la mezzo-soprano Mariam Sarkissian à Paris, ainsi que du baryton Jeremy Carpenter à Stockholm. <br /><br />

            Sur scène, il incarne des rôles majeurs tels que le Prince Charmant (*Cendrillon* de Pauline Viardot) et Hermosa (*L’île de Tulipatan* d’Offenbach) à l’Opéra Royal de Wallonie. Passionné par le répertoire français, il interprète Faust, Des Grieux (*Manon* de Massenet), et Scopetto (*La Sirène* d’Auber) au Théâtre Impérial de Compiègne.<br /><br />

            Il a récemment joué Don José (*Carmen* de Bizet) au Festival de Toûno en Suisse et Hoffmann (*Les Contes d’Hoffmann* d’Offenbach). Xavier est également créateur de rôles dans des œuvres contemporaines comme *Eden Park* et *Erotikos Factory* de Gérard Massini. <br /><br />

            Invité régulier d’orchestres prestigieux, il interprète des œuvres de Rossini (*Stabat Mater*), Dvorak, et Franck. Il s’illustre également dans l’opérette avec des rôles phares tels que Paganini (*Paganini* de Léhar), Sou-Chong (*Le Pays du Sourire*), et Piquillo (*La Périchole*).
          </p>
          <Link to="/biography" className="bio-button">
            Lire ma biographie
          </Link>
        </div>
      </section>

      <YouTubeVideos />
      <ArticlesDisplay />
      <FeaturedArtists />
    </div>
  );
}

export default Home;
